import { Container, Button, Row, Col, Form } from "react-bootstrap";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";
import "./DeliveryNoteDetail.css";
import { useEffect, useRef, useState, useMemo } from "react";
import { PDFDocument } from "pdf-lib";
import { useNavigate, useLocation } from "react-router-dom";
import DeliveryNote from "../Models/DeliveryNote";
import SignedDeliveryNote from "../Models/SignedDeliveryNote";
import { Document, Page, pdfjs } from "react-pdf";
import DeliveryNoteService from "../Services/DeliveryNoteService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { useMsal } from "@azure/msal-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function DeliveryNoteDetail(props: any) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const signPadRef = useRef(null) as React.MutableRefObject<any>;
  const closeButton = useRef<any>();
  const location = useLocation();
  const [bytes, setBytes] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);
  const [isPdfPresent, setIsPdfPresent] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState<DeliveryNote>(
    location.state as DeliveryNote
  );
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [signedDeliveryNote, setSignedDeliveryNote] = useState<any>();
  const [showSignPopup, setShowSignPopup] = useState(false);
  let emailContactPerson = "";

  const clearCanvas = () => signPadRef.current.clear();
  const saveCanvas = () => {
    if (isCanvasBlank()) {
      toast.error("Gelieve een handtekening te zetten.");
    } else {
      addImageToPdf(signPadRef.current.getCanvas().toDataURL("image/png"));
      closeButton.current.click();
    }
  };

  const deliveryNoteService = useMemo(
    () => new DeliveryNoteService(instance),
    [instance]
  );

  const pdfRenderData = useMemo(() => ({ data: bytes }), [bytes]);

  useEffect(() => {
    if (!location.state) {
      GoBackToList();
    }

    if (deliveryNote?.pdfBytes) {
      let bytes = deliveryNote.pdfBytes;

      PDFDocument.load(bytes).then((pdfDoc) => {
        pdfDoc.save().then((pdfBytes) => {
          setBytes(pdfBytes);
          setLoaded(true);
          setIsPdfPresent(true);
        });
      });
    } else {
      setLoaded(true);
      toast.error("PDF-bestand kon niet ingelezen worden.");
    }
  }, [deliveryNote]);

  function isCanvasBlank() {
    const canvas = signPadRef.current.getCanvas();
    const blank = document.createElement("canvas");

    blank.width = canvas.width;
    blank.height = canvas.height;

    return canvas.toDataURL() === blank.toDataURL();
  }

  async function addImageToPdf(imageUrl) {
    const pdfDoc = await PDFDocument.load(deliveryNote.pdfBytes);
    const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(imageBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[pages.length - 1];

    // Add image to pdf
    const pngDims = pngImage.scale(0.11);
    firstPage.drawImage(pngImage, {
      x: firstPage.getWidth() - 53 - pngDims.width,
      y: firstPage.getHeight() - 620 - pngDims.height,
      width: pngDims.width,
      height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save();

    if (deliveryNote) {
      const timestamp = Date.now();
      const fileName = `${
        deliveryNote.pdfReference.split(".")[0]
      }-${timestamp}-signed.pdf`;
      let newSignedDeliveryNote: SignedDeliveryNote = {
        deliveryNoteId: deliveryNote.id,
        fileName: fileName,
        fileBytes: pdfBytes.toString(),
        emailSubject: deliveryNote.emailSubject,
        contactEmail: "",
      };
      setEmailOfContactPerson();
      setSignedDeliveryNote(newSignedDeliveryNote);
    }
  }

  function setEmailOfContactPerson(): void {
    if (deliveryNote?.contactEmail === null) {
      setShowEmailPopup(true);
    } else {
      setShowConfirmationPopup(true);
    }
  }

  async function sendSignedDeliveryNoteWithEmail(
    newSignedDeliveryNote: SignedDeliveryNote
  ): Promise<void> {
    setLoaded(false);
    const isAccepted =
      await deliveryNoteService.sendSignedDeliveryNoteWithEmail(
        newSignedDeliveryNote
      );

    if (isAccepted) {
      toast.success("Leveringsbon is succesvol ondertekend.");
    } else {
      toast.error("Er ging iets fout!");
    }

    setLoaded(true);
    GoBackToList();
  }

  async function sendSignedDeliveryNoteWithoutEmail(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    setShowEmailPopup(false);

    if (signedDeliveryNote) {
      setLoaded(false);
      const isAccepted = await deliveryNoteService.sendSignedDeliveryNote(
        signedDeliveryNote
      );

      if (isAccepted) {
        toast.success("Leveringsbon is succesvol ondertekend.");
      } else {
        toast.error("Er ging iets fout!");
      }

      setLoaded(true);
    }

    GoBackToList();
  }

  async function saveEmailContactPerson(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (!isEmailValid(emailContactPerson)) {
      toast.error("E-mailadres is niet geldig!");
    } else {
      setShowEmailPopup(false);

      if (signedDeliveryNote) {
        signedDeliveryNote.contactEmail = emailContactPerson.trim();
        await sendSignedDeliveryNoteWithEmail(signedDeliveryNote);
      }

      GoBackToList();
    }
  }

  async function saveEmailContactPersonFromExistingEmail(event: any) {
    event.preventDefault();
    event.stopPropagation();
    setShowConfirmationPopup(false);

    if (signedDeliveryNote) {
      signedDeliveryNote.contactEmail = deliveryNote?.contactEmail;
      await sendSignedDeliveryNoteWithEmail(signedDeliveryNote);
    }

    GoBackToList();
  }

  function isEmailValid(email: string): boolean {
    const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
    return pattern.test(email.trim());
  }

  function setEmail(email: string): void {
    emailContactPerson = email;
  }

  function GoBackToList() {
    navigate("/delivery-notes");
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function previousPage() {
    setPageNumber(pageNumber - 1);
  }

  function nextPage() {
    setPageNumber(pageNumber + 1);
  }

  function isPreviousPagePossible(): boolean {
    return pageNumber > 1;
  }

  function isNextPagePossible(): boolean {
    return pageNumber < numPages;
  }

  function closeConfirmationPopupAndshowEmailPopup() {
    setShowConfirmationPopup(false);
    setShowEmailPopup(true);
  }

  function getLoadSpinner() {
    return (
      <MoonLoader
        color="black"
        loading={!loaded}
        css={css`
          display: block;
          position: absolute;
          left: calc(50% - 25px);
        `}
        size={50}
      />
    );
  }

  function getConfirmationPopup() {
    return (
      <Popup open={showConfirmationPopup} closeOnDocumentClick={false} modal>
        <Form
          onSubmit={saveEmailContactPersonFromExistingEmail}
          autoComplete="off"
          className="popup-email"
        >
          <h5>{`Bent u zeker dat u dit document naar ${deliveryNote?.contactEmail} wilt sturen?`}</h5>
          <Form.Group style={{ marginBottom: "0" }}>
            <Button className="popup-buttons" variant="success" type="submit">
              Ja
            </Button>
            <Button
              className="popup-buttons"
              variant="danger"
              onClick={closeConfirmationPopupAndshowEmailPopup}
            >
              Nee
            </Button>
          </Form.Group>
        </Form>
      </Popup>
    );
  }

  function getEmailPopup() {
    return (
      <Popup open={showEmailPopup} closeOnDocumentClick={false} modal>
        <Form
          onSubmit={saveEmailContactPerson}
          autoComplete="off"
          className="popup-email"
        >
          <h5>Vul een e-mailadres in voor de contactpersoon.</h5>
          <Form.Group style={{ marginBottom: "0" }}>
            <Form.Control
              required
              type="text"
              placeholder="E-mail"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "390px" }}
            />
            <Button className="popup-buttons" variant="success" type="submit">
              Verzend
            </Button>
            <Button
              className="popup-buttons"
              style={{ width: "140px" }}
              variant="success"
              onClick={sendSignedDeliveryNoteWithoutEmail}
            >
              Geen e-mail
            </Button>
            <Button
              className="popup-buttons"
              variant="danger"
              onClick={() => setShowEmailPopup(false)}
            >
              Annuleer
            </Button>
          </Form.Group>
        </Form>
      </Popup>
    );
  }

  return (
    <Container>
      {getConfirmationPopup()}
      {getEmailPopup()}
      <Row>
        <Col xs={8}>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#e2e2e5", color: "black" }}
            className="button-height"
            disabled={deliveryNote?.signed || !isPdfPresent}
            onClick={() => setShowSignPopup((o) => !o)}
          >
            <b>
              {deliveryNote?.signed ? "Reeds ondertekend" : "Teken document"}
            </b>
          </Button>
          <Popup
            modal
            open={showSignPopup}
            closeOnDocumentClick={false}
            onClose={() => setShowSignPopup(false)}
          >
            <div className="popup">
              <h3 style={{ textAlign: "center", userSelect: "none" }}>
                Handtekening
              </h3>
              <SignatureCanvas
                ref={signPadRef}
                canvasProps={{ className: "signature-canvas" }}
              />
              <div className="signature-buttons-div">
                <Button
                  variant="success"
                  className="popup-buttons"
                  onClick={saveCanvas}
                >
                  Opslaan
                </Button>
                <Button
                  variant="danger"
                  className="popup-buttons"
                  onClick={clearCanvas}
                >
                  Verwijder
                </Button>
                <Button
                  ref={closeButton}
                  variant="secondary"
                  className="popup-buttons"
                  onClick={() => setShowSignPopup(false)}
                >
                  Sluit
                </Button>
              </div>
            </div>
          </Popup>
          <Button
            variant="secondary"
            className="button-height"
            style={{
              marginLeft: "5px",
              backgroundColor: "#e2e2e5",
              color: "black",
            }}
            onClick={GoBackToList}
          >
            <b>Ga terug</b>
          </Button>
        </Col>
        <Col xs={4} className="button-end-aligned">
          <Button
            variant="secondary"
            className="button-height"
            style={{
              width: "70px",
              backgroundColor: "#e2e2e5",
              color: "black",
            }}
            disabled={!isPreviousPagePossible()}
            onClick={previousPage}
          >
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Button
            variant="secondary"
            className="button-height"
            style={{
              width: "70px",
              marginLeft: "5px",
              backgroundColor: "#e2e2e5",
              color: "black",
            }}
            disabled={!isNextPagePossible()}
            onClick={nextPage}
          >
            <i className="fas fa-arrow-right"></i>
          </Button>
        </Col>
      </Row>

      {getLoadSpinner()}

      <Row className="pdf-renderer">
        {loaded ? (
          <>
            <Document
              file={pdfRenderData}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          {loaded ? <h5>{`Pagina ${pageNumber} van ${numPages}`}</h5> : ""}
        </Col>
      </Row>
    </Container>
  );
}
