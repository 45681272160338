import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Status from "./Pages/Status";
import "bootstrap/dist/css/bootstrap.css";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionType,
} from "@azure/msal-browser";
import DeliveryNoteList from "./Pages/DeliveryNoteList";
import DeliveryNoteDetail from "./Pages/DeliveryNoteDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalConfig } from "./Config";
import Home from "./Pages/Home";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <ToastContainer closeOnClick />
      <Router>
        <NavBar />
        <div className="pl-2 pr-2 flex-grow-1 d-flex">
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <Routes>
              <Route path="/status" element={<Status />} />
              <Route path="/delivery-notes" element={<DeliveryNoteList />} />
              <Route path="/delivery-detail" element={<DeliveryNoteDetail />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </MsalAuthenticationTemplate>
        </div>
      </Router>
    </MsalProvider>
  );
};

export default App;
