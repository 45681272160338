import { useEffect, useState, useMemo } from "react";
import DeliveryNoteService from "../Services/DeliveryNoteService";
import {
  Container,
  Row,
  Col,
  ToggleButtonGroup,
  Button,
  Form,
} from "react-bootstrap";
import "./DeliveryNoteList.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import DeliveryNote from "../Models/DeliveryNote";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import { ButtonGroup } from "reactstrap";
import nl from "date-fns/locale/nl";
import SessionStorageService from "../Services/SessionStorageService";
import { useMsal } from "@azure/msal-react";
import CompanyType from "../Models/CompanyType";
import ProfcoatLogo from "../assets/profcoat-logo.png";
import VerfGroothandelLogo from "../assets/verfgroothandel-logo.png";
import MailBoxService from "../Services/MailBoxService";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";

enum FilterList {
  Delivery,
  TakeAway,
}

export default function DeliveryNoteList() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [filteredList, setFilteredList] = useState<DeliveryNote[]>([]);
  const [filter, setFilter] = useState<FilterList>(() => {
    const sessionFilter = SessionStorageService.getFilter();
    if (sessionFilter != null) {
      return sessionFilter;
    }
    return FilterList.Delivery;
  });
  const [checkedSigned, setCheckedSigned] = useState<boolean>(() => {
    const sessionCheckedState = SessionStorageService.getCheckedState();
    if (sessionCheckedState != null) {
      return sessionCheckedState;
    }
    return false;
  });
  const [selectedCompany, setSelectedCompany] = useState<CompanyType>(() => {
    const selectedCompanyState = SessionStorageService.getCompanyFilter();
    if (selectedCompanyState != null) {
      return selectedCompanyState;
    }
    return CompanyType.All;
  });
  const [loaded, setLoaded] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationId, setConfirationId] = useState(0);
  const deliveryNoteService = useMemo(
    () => new DeliveryNoteService(instance),
    [instance]
  );
  const mailBoxService = useMemo(
    () => new MailBoxService(instance),
    [instance]
  );

  useEffect(() => {
    registerLocale("nl", nl);
  }, [""]);

  useEffect(() => {
    refreshList();
  }, [""]);

  useEffect(() => {
    refreshList();
  }, [selectedCompany, filter, checkedSigned]);

  function refreshList(): void {
    loadList();
  }

  async function refreshListAndSyncDbWithMails() {
    setLoaded(false);
    await syncDbWithMails();
    refreshList();
  }

  async function syncDbWithMails(): Promise<void> {
    try {
      const isTriggered = await mailBoxService.triggerFillDbWithMails();
      if (!isTriggered) {
        toast.error("Er ging iets fout bij het uitlezen van de mailboxes.");
      }
    } catch (err) {
      toast.error("Er ging iets fout bij het uitlezen van de mailboxes.");
    }
  }

  async function loadList(): Promise<void> {
    setLoaded(false);

    deliveryNoteService
      .getAllDeliveryNotesByFilters(
        selectedCompany,
        filter === FilterList.Delivery ? "Delivery" : "TakeAway",
        checkedSigned
      )
      .then((deliveryNotes) => {
        if (deliveryNotes.length) {
          setFilteredList(sortDeliveryNotesFromOrderIds(deliveryNotes));
        } else {
          setFilteredList([]);
        }
      })
      .catch(() => {
        setFilteredList([]);
      });

    setLoaded(true);
  }

  function sortDeliveryNotesFromOrderIds(
    allDeliveryNotes: DeliveryNote[]
  ): DeliveryNote[] {
    let copiedDeliveryNotes = allDeliveryNotes.slice(0);
    const orderOfDeliveryNotesIds =
      SessionStorageService.getOrderOfDeliveryNotes();

    copiedDeliveryNotes
      .sort((item1, item2) => item2.date.getTime() - item1.date.getTime())
      .sort(
        (item1, item2) =>
          orderOfDeliveryNotesIds.indexOf(item1.id) -
          orderOfDeliveryNotesIds.indexOf(item2.id)
      )
      .sort((item1, item2) =>
        item1.signed === item2.signed ? 0 : item1.signed ? 1 : -1
      );

    return copiedDeliveryNotes;
  }

  async function navigateTo(deliveryNote: DeliveryNote) {
    setShowSpinner(true);
    deliveryNote.pdfBytes = await deliveryNoteService.getByteArrayFromPdf(
      deliveryNote.pdfReference
    );
    setShowSpinner(false);

    navigate("/delivery-detail", {
      state: deliveryNote,
    });
  }

  function handleOnDragEnd(result): void {
    if (!result.destination) return;

    const items = Array.from(filteredList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFilteredList(items);
    SessionStorageService.saveOrderOfDeliveryNotes(items);
  }

  function getLoadSpinner() {
    return (
      <MoonLoader
        color="black"
        loading={!loaded || showSpinner}
        css={css`
          display: block;
          position: absolute;
          left: calc(50% - 25px);
        `}
        size={50}
      />
    );
  }

  function getDisplayColorFilterButton(buttonFilter: FilterList) {
    if (filter === buttonFilter) {
      return { backgroundColor: "#cc3c34", color: "white" };
    } else {
      return { backgroundColor: "#e2e2e5", color: "black" };
    }
  }

  function getDisplayColorCompanyButton(companyType: CompanyType) {
    if (selectedCompany === companyType) {
      return { backgroundColor: "#cc3c34", color: "white" };
    } else {
      return { backgroundColor: "#e2e2e5", color: "black" };
    }
  }

  function onCompanyFilterButtonClick(companyType: CompanyType): void {
    SessionStorageService.saveCompanyFilter(companyType);
    setSelectedCompany(companyType);
  }

  function onFilterButtonClick(newFilter: FilterList): void {
    SessionStorageService.saveFilter(newFilter);
    setFilter(newFilter);
  }

  function onChangeCheckedSigned(): void {
    SessionStorageService.saveCheckedState(!checkedSigned);
    setCheckedSigned(!checkedSigned);
  }

  function getNoDataDiv() {
    return (
      <div className="deliveryNote">
        <h5 style={{ margin: "0" }}>Geen leveringsbonnen beschikbaar</h5>
      </div>
    );
  }

  function getLogoForDeliveryNote(deliveryNote: DeliveryNote) {
    if (deliveryNote.emailSubject.startsWith("PROFcoat")) {
      return (
        <img
          src={ProfcoatLogo}
          alt="Profcoat"
          style={{ width: "45px", height: "25px" }}
        />
      );
    }
    return (
      <img
        src={VerfGroothandelLogo}
        alt="Verfgroothandel"
        style={{ width: "144px", height: "20px" }}
      />
    );
  }

  async function markSigned(event: any) {
    event.preventDefault();
    event.stopPropagation();
    setShowConfirmationPopup(false);

    if (confirmationId && confirmationId > 0) {
      setLoaded(false);
      const isAccepted = await deliveryNoteService.markSignedDeliveryNote({
        deliveryNoteId: confirmationId,
        contactEmail: "",
        emailSubject: "",
        fileBytes: "",
        fileName: "",
      });

      setConfirationId(0);
      if (isAccepted) {
        toast.success("Aflevering is succesvol afgehandeld.");
      } else {
        toast.error("Er ging iets fout!");
      }

      setLoaded(true);

      refreshList();
    }
  }

  function getConfirmationPopup() {
    return (
      <Popup open={showConfirmationPopup} closeOnDocumentClick={false} modal>
        <Form onSubmit={markSigned} autoComplete="off" className="popup-email">
          <h5>{`Afgeleverd?`}</h5>
          <Form.Group style={{ marginBottom: "0" }}>
            <Button className="popup-buttons" variant="success" type="submit">
              Ja
            </Button>
            <Button
              className="popup-buttons"
              variant="danger"
              onClick={() => {
                setShowConfirmationPopup(false);
              }}
            >
              Nee
            </Button>
          </Form.Group>
        </Form>
      </Popup>
    );
  }

  return (
    <Container>
      {getConfirmationPopup()}
      <Row>
        <Col xs={9} className="dropdown-calender">
          <ButtonGroup style={{ margin: "0 10px 0 0" }}>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() => onCompanyFilterButtonClick(CompanyType.All)}
              style={getDisplayColorCompanyButton(CompanyType.All)}
            >
              <b>Alles</b>
            </Button>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() => onCompanyFilterButtonClick(CompanyType.Profcoat)}
              style={getDisplayColorCompanyButton(CompanyType.Profcoat)}
            >
              <b>Profcoat</b>
            </Button>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() =>
                onCompanyFilterButtonClick(CompanyType.VerfgroothandelHasselt)
              }
              style={getDisplayColorCompanyButton(
                CompanyType.VerfgroothandelHasselt
              )}
            >
              <b>Verfgr. H</b>
            </Button>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() =>
                onCompanyFilterButtonClick(
                  CompanyType.VerfgroothandelSintTruiden
                )
              }
              style={getDisplayColorCompanyButton(
                CompanyType.VerfgroothandelSintTruiden
              )}
            >
              <b>Verfgr. St. T</b>
            </Button>
          </ButtonGroup>

          <ButtonGroup>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() => onFilterButtonClick(FilterList.Delivery)}
              style={getDisplayColorFilterButton(FilterList.Delivery)}
            >
              <b>Leveren</b>
            </Button>
            <Button
              disabled={!loaded || showSpinner}
              variant="secondary"
              onClick={() => onFilterButtonClick(FilterList.TakeAway)}
              style={getDisplayColorFilterButton(FilterList.TakeAway)}
            >
              <b>Afhalen</b>
            </Button>
          </ButtonGroup>

          <ToggleButtonGroup
            type="checkbox"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <ToggleButton
              value="check"
              disabled={!loaded || showSpinner}
              className="checkbox"
              selected={checkedSigned}
              onChange={() => onChangeCheckedSigned()}
            >
              <CheckIcon />
            </ToggleButton>
            <h5 style={{ margin: "0 5px" }}>Alles</h5>
          </ToggleButtonGroup>
        </Col>
        <Col xs={3} className="button-end-aligned dropdown-calender">
          <Button
            style={{
              height: "100%",
              backgroundColor: "#cc3c34",
              marginLeft: "5px",
            }}
            variant="danger"
            onClick={refreshListAndSyncDbWithMails}
            disabled={!loaded || showSpinner}
          >
            <i
              className="fas fa-sync-alt align-self-center"
              style={{
                fontSize: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            ></i>
          </Button>
        </Col>
      </Row>

      {getLoadSpinner()}

      {loaded && !showSpinner ? (
        filteredList?.length > 0 ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="deliveryNotes">
              {(provided) => {
                return (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredList.map((deliveryNote, index) => {
                      return (
                        <Draggable
                          key={deliveryNote.id}
                          draggableId={deliveryNote.id.toString()}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div className="deliveryNote">
                                  <Row>
                                    <Col xs={9}>
                                      <Row>
                                        <Col xs={6}>
                                          {deliveryNote.customerName}
                                        </Col>
                                        <Col>
                                          {deliveryNote.date.toLocaleDateString() ??
                                            ""}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {deliveryNote.deliveryAddress ??
                                            `${deliveryNote.address ?? ""}, ${
                                              deliveryNote.postalCode ?? ""
                                            } ${deliveryNote.city ?? ""}`}
                                        </Col>
                                      </Row>
                                      <div className="logo">
                                        {getLogoForDeliveryNote(deliveryNote)}
                                      </div>
                                      <div className="signed-icon">
                                        {deliveryNote.signed ? (
                                          <CheckIcon />
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col xs={3}>
                                      {deliveryNote.pdfReference ? (
                                        <Button
                                          className="red-color"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          onClick={() =>
                                            navigateTo(deliveryNote)
                                          }
                                        >
                                          <i
                                            className="far fa-hand-point-up"
                                            style={{ fontSize: "30px" }}
                                          ></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          className="red-color"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          onClick={() => {
                                            setConfirationId(deliveryNote.id);
                                            setShowConfirmationPopup(true);
                                          }}
                                        >
                                          <i
                                            className="fas fa-check"
                                            style={{ fontSize: "30px" }}
                                          ></i>
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </li>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
        ) : (
          getNoDataDiv()
        )
      ) : null}
    </Container>
  );
}
